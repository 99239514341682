import {Serializable} from "@lib/common/serializable/Serializable"
import {computed, Ref, ref} from "vue"

const modifiedSymbol = Symbol("modified")
const unmodifiedSymbol = Symbol("unmodified")

export class Modified<T> {
    static readonly modified = modifiedSymbol
    public [modifiedSymbol]!: Ref<boolean>

    static wrap<T>(serializable: Serializable<T>): Serializable<T> {
        return (serializable[Modified.modified]
            ? serializable
            : new Modified(serializable)) as Serializable<T>
    }

    static use<T>(serializable: Serializable<T>) {
        const wrapped = Modified.wrap(serializable)
        return {wrapped: wrapped, modified: wrapped[Modified.modified]}
    }

    static unwrap<T>(serializable: Serializable<T>) {
        return serializable[Modified.modified]
            ? serializable[unmodifiedSymbol]
            : serializable
    }

    private constructor(serializable: Serializable<T>) {
        const modified = ref(false)
        const proxy = new Proxy(this, {
            get(target: Modified<T>, p: string | symbol, receiver: any): any {
                if (p === Modified.modified)
                    return computed(() => modified.value || Object.keys(serializable).any(key => serializable[key][Modified.modified]?.value))
                else if (p === unmodifiedSymbol)
                    return serializable
                else
                    return serializable[p]
            },
            set(target: Modified<T>, p: string | symbol, newValue: any, receiver: any): boolean {
                if (p === Modified.modified || p === unmodifiedSymbol)
                    return true
                serializable[p] = newValue
                modified.value = serializable.changed()
                return true
            },
        })
        Object.setPrototypeOf(proxy, serializable)
        return proxy
    }
}