import {ViewModel} from "@lib/common/model/ViewModel"
import {_props} from "@lib/common/_props"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Id} from "@lib/common/model/Id"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {ResourceCache} from "@lib/model/Resource"
import {AutomatischeRechnung} from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import {LocalDate} from "@lib/common/LocalDate"
import {Kuendigungsgrund} from "@generated/de/lohn24/model/mandant/Kuendigungsgrund"
import {MandantPmcStatus} from "@generated/de/lohn24/model/mandant/MandantPmcStatus"
import {MandantPmcEau} from "@generated/de/lohn24/model/mandant/MandantPmcEau"

export class MandantStammdatenViewModel extends ViewModel<MandantStammdatenViewModel> {
    static props = _props<InstanceType<typeof this>>()

    static label = {
        [this.props.name]: "Name",
        [this.props.kennung]: "Mandantennummer",
        [this.props.steuernummer]: "Steuernummer",
        [this.props.kooperationspartner]: "Kooperationspartner",
        [this.props.sammelrechnung]: "Sammelrechnung",
        [this.props.keinDirektkontakt]: "Kein Direktkontakt",
        [this.props.keineRechnung]: "Keine Rechnung",
        [this.props.direktvertrag]: "Direktvertrag",
        [this.props.automatischeRechnung]: "Automatische Rechnung",
        [this.props.klassifizierung]: "Klassifizierung",
        [this.props.kuendigungsdatum]: "Kündigungsdatum",
        [this.props.kuendigungsgrund]: "Kündigungsgrund",
        [this.props.kuendigungsgrundNotiz]: "Notiz",
        [this.props.organisation]: "Organisation",
        [this.props.portalStatus]: "Payroll Management Center",
        [this.props.pmcKostenpflichtig]: "PMC Kostenpflichtig",
        [this.props.pmcEau]: "PMC Modul eAU",
        organisationBearbeiten: "Organisation bearbeiten",
        organisationAnlegen: "Neue Organisation anlegen",
    }
    mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get id() {
        return this.mandant.id
    }

    get name() {
        return this.mandant.name
    }

    set name(value: string) {
        this.mandant.name = value
    }

    get kennung() {
        return this.mandant.kennung
    }

    set kennung(value) {
        if (!isNaN(+value)) {
            this.mandant.kennung = value
        }
    }

    get steuernummer() {
        return this.mandant.steuernummer
    }

    set steuernummer(value) {
        this.mandant.steuernummer = value === "" ? null : value
    }

    get kooperationspartner() {
        return this.mandant.kooperationspartner
    }

    set kooperationspartner(value: Id<Kooperationspartner> | null) {
        this.mandant.kooperationspartner = value
        if (value !== null) {

            const kooperationspartner = ResourceCache
                .kooperationspartner
                .mapped(value, it => it)
                .value

            if (kooperationspartner && kooperationspartner.sammelrechnung !== null)
                this.mandant.sammelrechnung = kooperationspartner.sammelrechnung

        }

    }

    get sammelrechnung() {
        return this.mandant.sammelrechnung
    }

    set sammelrechnung(value: Id<Kooperationspartner> | null) {
        this.mandant.sammelrechnung = value
    }

    get keinDirektkontakt() {
        return this.mandant.keinDirektkontakt
    }

    set keinDirektkontakt(value: boolean) {
        this.mandant.keinDirektkontakt = value
    }

    get keineRechnung() {
        return this.mandant.keineRechnung
    }

    set keineRechnung(value: boolean) {
        this.mandant.keineRechnung = value
    }

    get direktvertrag() {
        return this.mandant.direktvertrag
    }

    set direktvertrag(value: boolean) {
        this.mandant.direktvertrag = value
    }

    get automatischeRechnung() {
        return this.mandant.automatischeRechnung
    }

    set automatischeRechnung(value: AutomatischeRechnung) {
        this.mandant.automatischeRechnung = value
    }

    get klassifizierung(): Nullable<number> {
        return this.mandant.klassifizierung ?? 0
    }

    set klassifizierung(value: Nullable<number>) {
        this.mandant.klassifizierung = (value ?? 0) > 0 ? value : null
    }

    get kuendigungsdatum() {
        return this.mandant.kuendigungsdatum?.toJSDate()
    }

    set kuendigungsdatum(value: Date | undefined) {
        const nextValue = value ? LocalDate.fromJSDate(value) : null
        this.mandant.kuendigungsdatum = nextValue
        if (nextValue == null) {
            this.mandant.kuendigungsgrund = null
            this.mandant.kuendigungsgrundNotiz = null
        }
    }

    get kuendigungsgrund(): Kuendigungsgrund | null {
        return this.mandant.kuendigungsgrund
    }

    set kuendigungsgrund(value: Kuendigungsgrund | undefined) {
        this.mandant.kuendigungsgrund = value ? value : null
    }

    get kuendigungsgrundNotiz(): string | null {
        return this.mandant.kuendigungsgrundNotiz
    }

    set kuendigungsgrundNotiz(value: string | undefined) {
        this.mandant.kuendigungsgrundNotiz = value ? value : null
    }

    get organisation(): Id<Organisation> {
        return this.mandant.organisation
    }

    set organisation(value: Id<Organisation>) {
        this.mandant.organisation = value
    }

    get portalStatus() {
        return this.mandant.pmcStatus
    }

    set portalStatus(value: MandantPmcStatus) {
        this.mandant.pmcStatus = value
    }

    get pmcKostenpflichtig() {
        return this.mandant.pmcKostenpflichtig
    }

    set pmcKostenpflichtig(value: boolean) {
        this.mandant.pmcKostenpflichtig = value
    }

    get pmcEau(): MandantPmcEau {
        return this.mandant.pmcEau
    }

    set pmcEau(value: MandantPmcEau) {
        this.mandant.pmcEau = value
    }

    static errorCheckboxen(error: (name: string) => string) {
        return [
            this.props.keineRechnung,
            this.props.direktvertrag,
            this.props.keinDirektkontakt,
        ]
            .map(it => error(it))
            .filter(it => it)
            .join(", ")
    }
}