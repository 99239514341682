import {Repository} from "@lib/common/repository/Repository"
import {Failure} from "@generated/dev/api/model/failure/Failure"
import {FailureTreeNode, IFailureTreeNode} from "@generated/de/lohn24/endpoint/FailureTreeQuery/FailureTreeNode"
import {from} from "@lib/common/Functions"
import {FailureProcessingCommandBody} from "@generated/de/lohn24/endpoint/FailureProcessingCommand/FailureProcessingCommandBody"

export class FailureRepository extends Repository<Failure> {

    constructor() {
        super(Failure, "failure")
    }

    async process(body: FailureProcessingCommandBody) {
        await this.api.post(this.route("process"), body)
    }

    async treeNode(model?: string, className?: string, cause?: string): Promise<FailureTreeNode[]> {
        const result = await this.api.get<IFailureTreeNode[]>(this.route("tree"), {
            model,
            className,
            cause,
        })

        return result.map(it => from(FailureTreeNode, it))
    }
}