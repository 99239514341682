import {EditDialogTab} from "@lib/view/editdialog/EditDialogTab"
import {ValidationProperties} from "@lib/common/validator/ValidationProperties"
import {_props} from "@lib/common/_props"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {auth} from "@lib/common/Authentication"
import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"
import {RightAccess} from "@lib/model/role/RoleRight"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"

export class MandantStammdatenTab extends EditDialogTab {
    constructor(disabled: () => boolean = () => false) {
        super(
            "stammdaten",
            "Stammdaten",
            [
                new ValidationProperties(
                    "mandant",
                    [
                        _props<Mandant>().name,
                        _props<Mandant>().kennung,
                        _props<Mandant>().steuernummer,
                        _props<Mandant>().kooperationspartner,
                        _props<Mandant>().sammelrechnung,
                        _props<Mandant>().kuendigungsgrund,
                        _props<Mandant>().kuendigungsgrundNotiz,
                        _props<Mandant>().pmcStatus,
                        _props<Mandant>().pmcKostenpflichtig,
                        _props<Mandant>().pmcEau,
                    ],
                ),
                new ValidationProperties(
                    "rechnungsanschrift",
                    Object.values(Anschrift.props),
                ),
            ],
            disabled,
        )
    }
}

export class MandantDebitorTab extends EditDialogTab {
    constructor(disabled: () => boolean) {
        super(
            "debitor",
            "Rechnung",
            [
                new ValidationProperties(
                    _props<Mandant>().debitor,
                    Object.values(Debitor.props),
                ),
                new ValidationProperties(
                    _props<Debitor>().rechnungsanschrift,
                    Object.values(Anschrift.props),
                ),
                new ValidationProperties(
                    _props<Debitor>().postanschrift,
                    Object.values(Anschrift.props),
                ),
            ],
            disabled,
        )
    }

}

export class MandantOrganisationTab extends EditDialogTab {
    constructor(disabled: () => boolean = () => false) {
        super(
            "organisation",
            "Kontakt",
            [
                new ValidationProperties(
                    _props<Mandant>().organisation,
                    [_props<Organisation>().name],
                ),
                new ValidationProperties(
                    "kontakt",
                    Object.values(Kontakt.props),
                ),
            ],
            disabled,
        )
    }
}

export class MandantBenachrichtigungTab extends EditDialogTab {
    constructor() {
        super(
            "benachrichtigungsoption",
            "Benachrichtigungen",
            [],
        )
    }
}

export class MandantLohnabrechnungTab extends EditDialogTab {
    constructor() {
        super(
            "lohnabrechnung",
            "Lohnabrechnung",
            [
                new ValidationProperties(
                    "mandant",
                    [
                        _props<Mandant>().abschlusstag,
                        _props<Mandant>().bereitstellungLohn,
                        _props<Mandant>().bereitstellungZahlungen,
                        _props<Mandant>().bereitstellungZahlungenArt,
                    ],
                ),
            ],
        )
    }
}

export class MandantArtikelTab extends EditDialogTab {
    constructor() {
        super(
            "artikel",
            "Preise",
            [
                new ValidationProperties(
                    "artikel",
                    Object.values(Artikel.props),
                ),
            ],
            undefined,
            !auth.hasAccess([RightAccess.readable(RightIdentifier.MANDANT_ARTIKEL)]),
        )
    }
}
