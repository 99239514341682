import { IModel, IRuntimeTypeInformation, Id, LocalDate, LocalMonth, ModelBuilder } from "@lib/index"
import { IOrganisation, Organisation } from "@generated/de/lohn24/model/organisation/Organisation"
import { MandantStatus } from "@generated/de/lohn24/model/mandant/MandantStatus"
import { MandantPmcStatus } from "@generated/de/lohn24/model/mandant/MandantPmcStatus"
import { IMandantDebitorBuilder, MandantDebitorBuilder } from "@generated/de/lohn24/model/mandant/MandantBuilder/MandantDebitorBuilder"
import { Lohnsteueranmeldeperiode } from "@generated/de/lohn24/model/mandant/Lohnsteueranmeldeperiode"
import { LohnabrechnungSystem } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { BereitstellungOptionZahlungenArt } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import { BereitstellungOptionZahlungen } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import { BereitstellungOptionLohn } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import { Beitragsnachweis } from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import { AutomatischeRechnung } from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import { IAnschrift, Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IMandantBuilder extends IModel {
	kooperationspartner: string | null
	letzterSachbearbeiter: string | null
	sachbearbeiter: string | null
	sammelrechnung: string | null
	temporaererSachbearbeiter: string | null
	anschrift: IAnschrift
	automatischeRechnung: AutomatischeRechnung | null
	beitragsnachweis: Beitragsnachweis | null
	beitragAbbuchung: boolean | null
	direktvertrag: boolean | null
	keinDirektkontakt: boolean | null
	keineRechnung: boolean | null
	lohncall: boolean | null
	meldetSichVorAbschluss: boolean | null
	pmcKostenpflichtig: boolean | null
	bereitstellungLohn: Array<BereitstellungOptionLohn> | null
	bereitstellungZahlungen: Array<BereitstellungOptionZahlungen> | null
	bereitstellungZahlungenArt: Array<BereitstellungOptionZahlungenArt> | null
	abschlusstag: number | null
	kuendigungsdatum: LocalDate | null
	abrechnungszeitraum: string | null
	abrechnungssystem: LohnabrechnungSystem | null
	lohnsteueranmeldeperiode: Lohnsteueranmeldeperiode | null
	debitor: IMandantDebitorBuilder | null
	kennung: string | null
	pmcStatus: MandantPmcStatus | null
	status: MandantStatus | null
	organisation: IOrganisation | null
	abschlusshinweis: string | null
	bearbeitungshinweis: string | null
	betriebsnummer: string | null
	name: string | null
	steuernummer: string | null
	versandhinweis: string | null
}

export class MandantBuilder extends ModelBuilder<MandantBuilder> {
	public kooperationspartner!: Id<Kooperationspartner> | null
	public letzterSachbearbeiter!: Id<Account> | null
	public sachbearbeiter!: Id<Account> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public temporaererSachbearbeiter!: Id<Account> | null
	public anschrift!: Anschrift
	public automatischeRechnung!: AutomatischeRechnung | null
	public beitragsnachweis!: Beitragsnachweis | null
	public beitragAbbuchung!: boolean | null
	public direktvertrag!: boolean | null
	public keinDirektkontakt!: boolean | null
	public keineRechnung!: boolean | null
	public lohncall!: boolean | null
	public meldetSichVorAbschluss!: boolean | null
	public pmcKostenpflichtig!: boolean | null
	public bereitstellungLohn!: Array<BereitstellungOptionLohn> | null
	public bereitstellungZahlungen!: Array<BereitstellungOptionZahlungen> | null
	public bereitstellungZahlungenArt!: Array<BereitstellungOptionZahlungenArt> | null
	public abschlusstag!: number | null
	public kuendigungsdatum!: LocalDate | null
	public abrechnungszeitraum!: LocalMonth | null
	public abrechnungssystem!: LohnabrechnungSystem | null
	public lohnsteueranmeldeperiode!: Lohnsteueranmeldeperiode | null
	public debitor!: MandantDebitorBuilder | null
	public kennung!: string | null
	public pmcStatus!: MandantPmcStatus | null
	public status!: MandantStatus | null
	public organisation!: Organisation | null
	public abschlusshinweis!: string | null
	public bearbeitungshinweis!: string | null
	public betriebsnummer!: string | null
	public name!: string | null
	public steuernummer!: string | null
	public versandhinweis!: string | null

	static _types: Record<keyof IMandantBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		kooperationspartner: { type: Id, nullable: true },
		letzterSachbearbeiter: { type: Id, nullable: true },
		sachbearbeiter: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		temporaererSachbearbeiter: { type: Id, nullable: true },
		anschrift: { type: Anschrift },
		automatischeRechnung: { type: AutomatischeRechnung, nullable: true },
		beitragsnachweis: { type: Beitragsnachweis, nullable: true },
		beitragAbbuchung: { type: Boolean, nullable: true },
		direktvertrag: { type: Boolean, nullable: true },
		keinDirektkontakt: { type: Boolean, nullable: true },
		keineRechnung: { type: Boolean, nullable: true },
		lohncall: { type: Boolean, nullable: true },
		meldetSichVorAbschluss: { type: Boolean, nullable: true },
		pmcKostenpflichtig: { type: Boolean, nullable: true },
		bereitstellungLohn: { type: [BereitstellungOptionLohn], nullable: true },
		bereitstellungZahlungen: { type: [BereitstellungOptionZahlungen], nullable: true },
		bereitstellungZahlungenArt: { type: [BereitstellungOptionZahlungenArt], nullable: true },
		abschlusstag: { type: Number, nullable: true },
		kuendigungsdatum: { type: LocalDate, nullable: true },
		abrechnungszeitraum: { type: LocalMonth, nullable: true },
		abrechnungssystem: { type: LohnabrechnungSystem, nullable: true },
		lohnsteueranmeldeperiode: { type: Lohnsteueranmeldeperiode, nullable: true },
		debitor: { type: MandantDebitorBuilder, nullable: true },
		kennung: { type: String, nullable: true },
		pmcStatus: { type: MandantPmcStatus, nullable: true },
		status: { type: MandantStatus, nullable: true },
		organisation: { type: Organisation, nullable: true },
		abschlusshinweis: { type: String, nullable: true },
		bearbeitungshinweis: { type: String, nullable: true },
		betriebsnummer: { type: String, nullable: true },
		name: { type: String, nullable: true },
		steuernummer: { type: String, nullable: true },
		versandhinweis: { type: String, nullable: true },
	}

	static props: Record<keyof IMandantBuilder, string> = {
		id: "id",
		kooperationspartner: "kooperationspartner",
		letzterSachbearbeiter: "letzterSachbearbeiter",
		sachbearbeiter: "sachbearbeiter",
		sammelrechnung: "sammelrechnung",
		temporaererSachbearbeiter: "temporaererSachbearbeiter",
		anschrift: "anschrift",
		automatischeRechnung: "automatischeRechnung",
		beitragsnachweis: "beitragsnachweis",
		beitragAbbuchung: "beitragAbbuchung",
		direktvertrag: "direktvertrag",
		keinDirektkontakt: "keinDirektkontakt",
		keineRechnung: "keineRechnung",
		lohncall: "lohncall",
		meldetSichVorAbschluss: "meldetSichVorAbschluss",
		pmcKostenpflichtig: "pmcKostenpflichtig",
		bereitstellungLohn: "bereitstellungLohn",
		bereitstellungZahlungen: "bereitstellungZahlungen",
		bereitstellungZahlungenArt: "bereitstellungZahlungenArt",
		abschlusstag: "abschlusstag",
		kuendigungsdatum: "kuendigungsdatum",
		abrechnungszeitraum: "abrechnungszeitraum",
		abrechnungssystem: "abrechnungssystem",
		lohnsteueranmeldeperiode: "lohnsteueranmeldeperiode",
		debitor: "debitor",
		kennung: "kennung",
		pmcStatus: "pmcStatus",
		status: "status",
		organisation: "organisation",
		abschlusshinweis: "abschlusshinweis",
		bearbeitungshinweis: "bearbeitungshinweis",
		betriebsnummer: "betriebsnummer",
		name: "name",
		steuernummer: "steuernummer",
		versandhinweis: "versandhinweis",
	}

	constructor(_json: IMandantBuilder) {
		super(_json)
	}
}
