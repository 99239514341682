import {Repository} from "@lib/common/repository/Repository"
import {IPbxCall, PbxCall} from "@generated/de/lohn24/model/pbx/PbxCall"

export class PbxCallRepository extends Repository<PbxCall> {
    constructor() {
        super(PbxCall, "pbx")
    }

    async calls(start: Date, end: Date): Promise<PbxCall[]> {
        const result = await this.api.get<IPbxCall[]>(this.route("call"), {start: start, end: end})
        return this.deserialize(result)
    }
}